import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';
import './ui';

import { enablePlatformAdaptation } from '~/utils/styles';

enablePlatformAdaptation();

export const App = (): JSX.Element => {
  return (
    <>
      <Helmet titleTemplate="%s | Payment" />

      <RouterProvider router={router} />
    </>
  );
};
