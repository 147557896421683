import type { AxiosRequestConfig } from 'axios';
import qs from 'qs';

export const DEFAULT_CONFIG: AxiosRequestConfig = {
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: (params) => {
      return qs.stringify(params, {
        allowDots: true,
        arrayFormat: 'brackets',
        skipNulls: true,
      });
    },
  },
  responseType: 'json',
  timeout: 60000,
  withCredentials: false,
};
