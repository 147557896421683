import * as React from 'react';
import type { RouteObject } from 'react-router-dom';

import { Layout } from '~/components/Layout';
import { Result } from '~/components/Result';
import { loadable } from '~/utils/loadable';

type RouteCreator = (
  params: Pick<RouteObject, 'path'> & {
    loader: () => Promise<{ default: React.ComponentType }>;
  },
) => RouteObject;

export const createRoute: RouteCreator = (params) => {
  const { loader, path } = params;

  const Element = loadable(loader);

  return {
    element: <Element />,
    errorElement: (
      <Layout>
        <Result status="error" title="Unknown error" />
      </Layout>
    ),
    path,
  };
};
