import { css } from '@linaria/core';
import * as R from 'rambda';

const withAndroidAdaptation = css``;
const withIOsAdaptation = css``;

export const enablePlatformAdaptation = (): void => {
  const platformClassName = R.cond([
    [R.test(/Android/i), R.always(withAndroidAdaptation)],
    [R.test(/iPad|iPhone|iPod/i), R.always(withIOsAdaptation)],
    [R.T, R.always(undefined)],
  ])(navigator.userAgent);

  if (platformClassName) {
    // Сделано не через <Helmet />, так как с ним происходит лишний ререндер.
    document.documentElement.classList.add(platformClassName);
  }
};

export const Platform = {
  android: `.${withAndroidAdaptation}`,
  ios: `.${withIOsAdaptation}`,
};
