import type { Token } from '../types';

import { request } from '~/utils/request';

type TokenizeMethod = <Payload extends Record<string, unknown>>(params: {
  hash: string;
  payload: Payload;
  sign: string;
}) => Promise<{
  title: string;
  token: Token;
  type: 'alipay';
}>;

/**
 * Возвращает токен данных.
 */
export const tokenize: TokenizeMethod = async (params) => {
  const { hash, payload, sign } = params;

  const { data } = await request.post(
    `/apm/tokenize/${hash}/${sign}/alipay`,
    payload,
  );

  return data;
};
