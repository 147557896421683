import axios from 'axios';
import * as R from 'rambda';

import { DEFAULT_CONFIG } from './constants';
import type { RequestCreator } from './types';

export const createRequest: RequestCreator = R.compose(
  // eslint-disable-next-line @typescript-eslint/unbound-method
  axios.create,
  R.mergeDeepRight(DEFAULT_CONFIG),
  R.defaultTo({}),
);
