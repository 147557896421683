import { styled } from '@linaria/react';

import ErrorIcon from './images/error-icon.svg';
import SuccessIcon from './images/success-icon.svg';

export const Root = styled.section`
  display: flex;
  min-height: 100%;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
`;

export const Icon = styled.div`
  margin: 16px 0;
`;

export const ErrorIconStyled = styled(ErrorIcon)`
  color: var(--destructive-text-color);
`;

export const SuccessIconStyled = styled(SuccessIcon)`
  color: var(--accent-text-color);
`;

export const Title = styled.h1`
  margin: 0 0 2px;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
`;

export const Description = styled.div`
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;
`;
