import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import { Platform } from '~/utils/styles';

export const root = css`
  display: grid;
  min-height: 100%;
  grid-auto-flow: row;
  grid-template-rows: 1fr auto;
`;

export const Main = styled.main`
  padding: 32px 32px 0;

  ${Platform.android} & {
    padding: 0;
  }

  ${Platform.ios} & {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const Footer = styled.footer`
  ${Platform.ios} & {
    padding-right: 16px;
    padding-bottom: 48px;
    padding-left: 16px;
  }
`;
