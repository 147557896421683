import { TokenType } from '~/hooks/useTelegramGameProxy';
import { Token } from '~/types';
import { request } from '~/utils/request';

type TokenizeMethod = (params: { hash: string; sign: string }) => Promise<{
  title: string;
  token: Token;
  type: TokenType.FPS;
}>;

/**
 * Возвращает токен данных.
 */
export const tokenize: TokenizeMethod = async (params) => {
  const { hash, sign } = params;

  const { data } = await request.post(`/apm/tokenize/${hash}/${sign}/fps`, {});

  return data;
};
