import { createBrowserRouter } from 'react-router-dom';

import { route as apmAlipayPageRoute } from '~/containers/APMAlipayPage';
import { route as apmFpsPageRoute } from '~/containers/APMFPSPage';
import { route as apmPaymentPageRoute } from '~/containers/APMPaymentPage';
import { route as apmSberPayPageRoute } from '~/containers/APMSberPayPage';
import { route as apmTokenizePageRoute } from '~/containers/APMTokenizePage';
import { route as notFoundPageRoute } from '~/containers/NotFoundPage';
import { route as sberPaySessionStatusPageRoute } from '~/containers/SberPaySessionStatusPage';
import { route as secureCodePageRoute } from '~/containers/SecureCodePage';
import { route as sessionStatusPageRoute } from '~/containers/SessionStatusPage';
import { route as smsStatusPageRoute } from '~/containers/SmsCodePage';
import { route as upiPageRoute } from '~/containers/UPIPage';

export const router = createBrowserRouter([
  {
    path: '/:lang/',
    children: [
      apmAlipayPageRoute,
      apmFpsPageRoute,
      apmPaymentPageRoute,
      apmSberPayPageRoute,
      apmTokenizePageRoute,
      sberPaySessionStatusPageRoute,
      secureCodePageRoute,
      sessionStatusPageRoute,
      smsStatusPageRoute,
      upiPageRoute,
      notFoundPageRoute,
    ],
  },
  notFoundPageRoute,
]);
