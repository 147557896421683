import { useParams } from 'react-router-dom';

type UseCredentialsUrlHook = (params: {
  hashAlias: string;
  signAlias: string;
}) => {
  hash: string;
  sign: string;
};

export const useCredentialsUrl: UseCredentialsUrlHook = (params) => {
  const { hashAlias, signAlias } = params;

  const { [hashAlias]: hash, [signAlias]: sign } = useParams();

  if (!hash || !sign) {
    throw new Error(
      `Проверь наличие в роуте параметров авторизации ":${hashAlias}" и ":${signAlias}".`,
    );
  }

  return {
    hash,
    sign,
  };
};
