import * as React from 'react';

import {
  ErrorIconStyled,
  Icon,
  Root,
  SuccessIconStyled,
  Title,
  Description,
} from './ui';

export interface ResultProps {
  status: 'error' | 'success';
  title: React.ReactNode;

  children?: React.ReactNode;
}

export const Result = (props: ResultProps): JSX.Element => {
  const { children, status, title } = props;

  const descriptionId = React.useId();

  const titleId = React.useId();

  const IconComponent = React.useMemo(() => {
    return {
      error: ErrorIconStyled,
      success: SuccessIconStyled,
    }[status];
  }, [status]);

  return (
    <Root>
      <Icon
        aria-describedby={descriptionId}
        as={IconComponent}
        aria-labelledby={titleId}
      />

      <Title id={titleId}>{title}</Title>

      <Description id={descriptionId}>{children}</Description>
    </Root>
  );
};
