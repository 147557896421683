import { useParams } from 'react-router-dom';

export enum ParamName {
  Hash = 'hashTokenization',
  Sign = 'signTokenization',
}

type UseCredentialsHook = () => {
  hash: string;
  sign: string;
};

export const useCredentials: UseCredentialsHook = () => {
  const { [ParamName.Hash]: hash, [ParamName.Sign]: sign } = useParams();

  if (!hash || !sign) {
    throw new Error(
      `Проверь наличие в роуте параметров авторизации ":${ParamName.Hash}" и ":${ParamName.Sign}".`,
    );
  }

  return {
    hash,
    sign,
  };
};
