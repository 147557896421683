import * as React from 'react';

import { Footer, Main, root } from './ui';

interface LayoutProps<P> {
  children: React.ReactNode | React.ReactNode[];

  footer?: React.ReactNode;
  RootComponent?: string | React.ComponentType<P>;
  rootProps?: P;
}

export const Layout = <P,>(props: LayoutProps<P>): JSX.Element => {
  const {
    children,
    footer,
    RootComponent = 'div',
    rootProps = {} as P,
  } = props;

  return (
    <RootComponent {...rootProps} className={root}>
      <Main>{children}</Main>

      {footer ? <Footer>{footer}</Footer> : null}
    </RootComponent>
  );
};
