import { ParamName } from './constants';

import { useCredentialsUrl } from '~/hooks/useCredentialsUrl';

export const usePaymentCredentials = () => {
  return useCredentialsUrl({
    hashAlias: ParamName.Hash,
    signAlias: ParamName.Sign,
  });
};
